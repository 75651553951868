<template>
  <div>
    <b-card id="filter-form">
      <b-form @submit.prevent="fetchMobilUnit">
        <b-row>
          <b-col cols="12" md="6">
            <label class="hp-input-label" :for="filter.date"
              >Pilih Tanggal</label
            >
            <b-form-input
              :id="filter.date"
              type="date"
              v-model="filter.date"
            ></b-form-input>
          </b-col>
          <b-col cols="12" md="6">
            <label class="hp-input-label" :for="filter.bloodVanId"
              >Pilih Mobil Unit</label
            >
            <b-form-select
              :id="filter.bloodVanId"
              :options="bloodVanOptions"
              v-model="filter.bloodVanId"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-button
          class="float-right mt-3"
          type="submit"
          variant="primary"
          :disabled="!isFormValid"
          >Tampilkan Laporan</b-button
        >
      </b-form>
    </b-card>

    <b-card id="print" class="mt-3">
      <div class="d-flex justify-content-end">
        <b-button
          id="printButton"
          variant="outline-primary"
          :disabled="!isFormValid"
          @click="printElement"
          >Print</b-button
        >
      </div>
      <h2 class="text-center">
        Laporan kegiatan
        {{
          bloodVanOptions.find((opt) => opt.value === filter.bloodVanId).text
        }}
      </h2>
      <h4 class="text-center">{{ informasi.alamatDonor }}</h4>

      <p class="text-right">Tanggal: {{ filter.date }}</p>

      <!-- Golongan Darah Section -->
      <h4 class="w-75 mx-auto">
        Total Pendonor: {{ informasi.totalPendonor }}
      </h4>
      <section class="border rounded mb-5 w-75 mx-auto">
        <p class="font-bold rounded bg-light text-center m-0 pt-2">
          <strong>Darah yang diperoleh</strong>
        </p>
        <table
          class="table table-bordered table-striped table-hover table-sm mb-0"
        >
          <tr v-for="item in golonganDarah.items" :key="item.golDarahA">
            <th>Gol. Darah A</th>
            <td>{{ item.golDarahA }}</td>
          </tr>

          <tr v-for="item in golonganDarah.items" :key="item.golDarahB">
            <th>Gol. Darah B</th>
            <td>{{ item.golDarahB }}</td>
          </tr>

          <tr v-for="item in golonganDarah.items" :key="item.golDarahAB">
            <th>Gol. Darah AB</th>
            <td>{{ item.golDarahAB }}</td>
          </tr>

          <tr v-for="item in golonganDarah.items" :key="item.golDarahO">
            <th>Gol. Darah O</th>
            <td>{{ item.golDarahO }}</td>
          </tr>
        </table>
        <p class="font-bold rounded bg-light text-right m-0 pt-2 px-5">
          <strong>Total diterima: </strong>{{ informasi.totalTerima }}
        </p>
      </section>

      <!-- Gagal Section -->
      <section class="border rounded mb-5 w-75 mx-auto">
        <p class="font-bold rounded bg-light text-center m-0 pt-2">
          <strong>Alasan Gagal</strong>
        </p>
        <table
          class="table table-bordered table-striped table-hover table-sm mb-0"
        >
          <tr v-for="item in gagal.items" :key="item.hbRendah">
            <th>HB Rendah</th>
            <td>{{ item.hbRendah }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.hbTinggi">
            <th>HB Tinggi</th>
            <td>{{ item.hbTinggi }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.tensiRendah">
            <th>Tensi Rendah</th>
            <td>{{ item.tensiRendah }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.tensiTinggi">
            <th>Tensi Tinggi</th>
            <td>{{ item.tensiTinggi }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.pilek">
            <th>Pilek</th>
            <td>{{ item.pilek }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.menyusui">
            <th>Menyusui</th>
            <td>{{ item.menyusui }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.operasiGigi">
            <th>Operasi Gigi</th>
            <td>{{ item.operasiGigi }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.alergi">
            <th>Alergi</th>
            <td>{{ item.alergi }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.mislek">
            <th>Mislek</th>
            <td>{{ item.mislek }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.tidakJadi">
            <th>Tidak Jadi</th>
            <td>{{ item.tidakJadi }}</td>
          </tr>

          <tr v-for="item in gagal.items" :key="item.lainLain">
            <th>Lainnya</th>
            <td>{{ item.lainLain }}</td>
          </tr>
        </table>
        <p class="font-bold rounded bg-light text-right m-0 pt-2 px-5">
          <strong>Total gagal:</strong> {{ informasi.totalGagal }}
        </p>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormSelect,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import reportMobilUnitAPI from "../../../api/laporan/mobilUnitAPI";
import manageBloodvanAPI from "../../../api/managebloodvan/manageBloodvanAPI";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      filter: {
        date: "",
        bloodVanId: "",
      },
      bloodVanOptions: [{ value: "", text: "Pilih Mobil Unit Darah" }],
      golonganDarah: {
        fields: [
          { key: "golDarahA", label: "Gol. Darah A" },
          { key: "golDarahB", label: "Gol. Darah B" },
          { key: "golDarahAB", label: "Gol. Darah AB" },
          { key: "golDarahO", label: "Gol. Darah O" },
        ],
        items: [
          {
            golDarahA: "",
            golDarahB: "",
            golDarahAB: "",
            golDarahO: "",
          },
        ],
      },
      gagal: {
        fields: [
          { key: "hbRendah", label: "HB Rendah" },
          { key: "hbTinggi", label: "HB Tinggi" },
          { key: "tensiRendah", label: "Tensi Rendah" },
          { key: "tensiTinggi", label: "Tensi Tinggi" },
          { key: "pilek", label: "Pilek" },
          { key: "menyusui", label: "Menyusui" },
          { key: "operasiGigi", label: "Operasi Gigi" },
          { key: "alergi", label: "Alergi" },
          { key: "mislek", label: "Mislek" },
          { key: "tidakJadi", label: "Tidak Jadi" },
          { key: "lainLain", label: "lainnya" },
        ],
        items: [
          {
            hbRendah: "",
            hbTinggi: "",
            tensiRendah: "",
            tensiTinggi: "",
            pilek: "",
            menyusui: "",
            operasiGigi: "",
            alergi: "",
            mislek: "",
            tidakJadi: "",
            lainLain: "",
          },
        ],
      },
      informasi: {
        alamatDonor: "",
        totalPendonor: "",
        totalTerima: "",
        totalGagal: "",
      },
    };
  },
  computed: {
    isFormValid() {
      return this.filter.bloodVanId && this.filter.date;
    },
  },
  methods: {
    async fetchBloodVan() {
      const response = await manageBloodvanAPI.getAll({ per_page: 100 });
      this.bloodVanOptions = [
        { value: "", text: "Pilih Mobil Unit" },
        ...response.data.data.data.map((bloodVan) => ({
          value: bloodVan.id,
          text: bloodVan.name,
        })),
      ];
    },
    async fetchMobilUnit() {
      const response = await reportMobilUnitAPI.getDetail({
        date: this.filter.date,
        blood_van_id: this.filter.bloodVanId,
      });

      const donorActivity = response.data.donor_activity;
      const reasonRejected = donorActivity.reason_rejected;

      this.golonganDarah.items = [
        {
          golDarahA: donorActivity.blood["A+"] || 0,
          golDarahB: donorActivity.blood["B+"] || 0,
          golDarahAB: donorActivity.blood["AB+"] || 0,
          golDarahO: donorActivity.blood["O+"] || 0,
        },
      ];

      this.gagal.items = [
        {
          hbRendah: reasonRejected["hb rendah"] || 0,
          hbTinggi: reasonRejected["hb tinggi"] || 0,
          tensiRendah: reasonRejected["Tensi Rendah"] || 0,
          tensiTinggi: reasonRejected["Tensi Tinggi"] || 0,
          pilek: reasonRejected["Pilek"] || 0,
          menyusui: reasonRejected["Menyusui"] || 0,
          operasiGigi: reasonRejected["Operasi Gigi"] || 0,
          alergi: reasonRejected["Alergi"] || 0,
          mislek: reasonRejected["mislek"] || 0,
          tidakJadi: reasonRejected["Tidak jadi"] || 0,
          lainLain: reasonRejected["lain-lain"] || 0,
        },
      ];

      this.informasi.alamatDonor = response.data.donor_place;
      this.informasi.totalPendonor = donorActivity.donor_register;
      this.informasi.totalTerima = donorActivity.donor_approved;
      this.informasi.totalGagal = donorActivity.donor_rejected;
    },
    printElement() {
      const printButton = document.getElementById("printButton");
      const printContents = document.getElementById("print").innerHTML;
      const originalContents = document.body.innerHTML;
      printButton.style.display = "none";
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      printButton.style.display = "block";
      window.location.reload();
    },
  },
  mounted() {
    this.fetchBloodVan();
  },
};
</script>

<style scoped>
td {
  text-align: center;
}

@media print {
  #printContents * {
    display: none !important;
  }
  #printButton {
    display: none !important;
  }
  #filter-form {
    display: none !important;
  }
  #printContents {
    display: block !important;
  }
}
</style>
