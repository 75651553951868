import API from "../services/services";

class laporanMobilUnitService {
  getDetail(params) {
    return API.get("manage_blood_van/show_report_van_activity", {
      params,
    });
  }

  uploadExcelReport(payload) {
    return API.post(`front_office/import_proses`, payload);
  }
}

export default new laporanMobilUnitService();
